/*
 * @Author: dupeicheng dupeicheng@sinsegye.com.cn
 * @Date: 2024-08-05 15:17:37
 * @LastEditors: dupeicheng dupeicheng@sinsegye.com.cn
 * @LastEditTime: 2024-08-08 16:14:11
 */
export enum RequestCodeEnum {
  SUCCESS = 200,
  FAIL = 400,
  ILLEGALITY = 401,
  NOTFOUND = 404,
  ERROR = 500
}
