import axios, { AxiosResponse } from 'axios';
import type { Method, AxiosRequestConfig } from 'axios';
import cache from './cache';
import { RequestCodeEnum } from '@/enums/RequestCode';
// import { refreshTokenApi } from '@/apis/loginApi';
// import mitt from 'mitt';
export const wsUrl = process.env.REACT_APP_WSURL;
export const service = axios.create({ baseURL: process.env.REACT_APP_BASEURL, timeout: 30000 });

// 设置axios实例为全局
export interface IAxiosError extends Error {
  config: AxiosRequestConfig;
  code?: string | null | number;
  request?: unknown;
  response?: AxiosResponse;
}

// export const requestEmitter = mitt();
// service.interceptors.response.use(
//   response => response.data,
//   async error => {
//     if (
//       error.response != undefined &&
//       error.response.status != undefined &&
//       error.response.status === 401 &&
//       !error.config.url.includes('/RefreshToken')
//     ) {
//       // token过期，刷新token
//       const newRes = await refreshTokenFun(error);
//       return newRes;
//     }
//     return Promise.reject(error);
//   }
// );
service.interceptors.response.use(
  res => res.data,
  error => {
    if (error.response.status === RequestCodeEnum.ILLEGALITY) {
      cache().clear();
      window.location.href = '/login';
    }
    if (error.response.status === RequestCodeEnum.FAIL) {
      return error.response.data;
    }
    return error;
  }
);

const request = async (url: string, method: Method, params?: object, config?: AxiosRequestConfig) => {
  const token = cache().get('token', 'hasNotToken');
  try {
    const res = await service.request<requestBaseData.IBaseData<any>>({
      url,
      method,
      headers: { Authorization: 'Bearer ' + token },
      [method.toLocaleLowerCase() === 'get' ? 'params' : 'data']: params,
      ...config
    });
    return res;
    // eslint-disable-next-line
  } catch (e: any) {
    return e;
  }
};

// const handleLoginOut = () => {
//   requestEmitter.emit('loginOut');
//   return { code: 401, message: '登录失效，请重新登录' };
// };
// 刷新token并进行网络重发
// const refreshTokenFun = async (error: any) => {
//   const refreshToken = window.localStorage.getItem('refreshToken');
//   if (!refreshToken) return handleLoginOut();

//   const res = await refreshTokenApi(refreshToken);
//   if (res != undefined && res.code == 0 && res.data) {
//     window.localStorage.setItem('refreshToken', res.data.refreshToken);
//     window.localStorage.setItem('token', res.data.token);
//     window.localStorage.setItem('machineType', res.data.machineType);
//     requestEmitter.emit('refreshToken');
//     // 重新发起请求
//     const newResponse = await service.request({
//       ...error.config,
//       headers: { Authorization: 'Bearer ' + res.data.token }
//     });
//     return newResponse;
//   } else {
//     return handleLoginOut();
//   }
// };

/**
 * Get请求
 * @param {string} url 请求url地址，不要携带http或https 例：/api/user
 * @param {object} config 请求额外头信息配置 object
 * @returns Promise<any>
 */
export const get = (url: string, params = {}, config = {}) => request(url, 'get', params, config);

/**
 * Delete请求
 * @param {string} url 请求url地址，不要携带http或https 例：/api/user
 * @param {object} config 请求额外头信息配置 object
 * @returns Promise<any>
 */
export const del = (url: string, params = {}, config = {}) => request(url, 'delete', params, config);

/**
 * Post请求
 * @param {string} url 请求url地址，不要携带http或https 例：/api/user
 * @param {object|FormData} data 请求体数据
 * @param {object} config 请求额外头信息配置 object
 * @returns Promise<any>
 */
export const post = async (url: string, data: any, config = {}) => request(url, 'post', data, config);

/**
 * Put请求
 * @param {string} url 请求url地址，不要携带http或https 例：/api/user
 * @param {object|FormData} data 请求体数据
 * @param {object} config 请求额外头信息配置 object
 * @returns Promise<any>
 */
export const put = async (url: string, data: any, config = {}) => request(url, 'put', data, config);

export default request;
